import React from 'react'

import Inline from 'yet-another-react-lightbox/plugins/inline'
import Lightbox from 'yet-another-react-lightbox'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'

const ProductLightbox = ({ imageUrls }) => {
  const [open, setOpen] = React.useState(false)
  const [index, setIndex] = React.useState(0)

  const toggleOpen = (state) => () => setOpen(state)
  const updateIndex = ({ index }) => setIndex(index)

  const mediumImages = imageUrls.map(({ medium }) => ({ src: medium }))
  const originalImages = imageUrls.map(({ original }) => ({ src: original }))

  const rootStyles = {
    '--yarl__color_button': '#212529',
    '--yarl__color_button_active': '#424649',
  }

  return (
    <>
      <Lightbox
        index={index}
        slides={mediumImages}
        plugins={[Inline]}
        on={{
          view: updateIndex,
          click: toggleOpen(true),
        }}
        carousel={{
          finite: true,
          padding: '0px',
        }}
        styles={{
          container: {
            backgroundColor: 'transparent',
          },
          root: rootStyles,
        }}
      />

      <Lightbox
        open={open}
        close={toggleOpen(false)}
        index={index}
        slides={originalImages}
        plugins={[Zoom]}
        on={{ view: updateIndex }}
        animation={{ fade: 0 }}
        carousel={{ finite: true }}
        controller={{
          closeOnPullDown: true,
          closeOnBackdropClick: true,
        }}
        zoom={{
          scrollToZoom: true,
          maxZoomPixelRatio: 2,
          wheelZoomDistanceFactor: 1000,
        }}
        styles={{
          container: {
            backgroundColor: '#fff',
          },
          root: rootStyles,
        }}
      />
    </>
  )
}

export default ProductLightbox
