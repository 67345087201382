import React, { useState } from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'

import CartCookie from './CartCookie'
import cartCountAdd from './cartCountAdd'

const numberWithPrecision = (value, delimiter = ' ') => {
  const chs = parseInt(value).toString().split('').reverse()
  const chunks = []

  for (let i = 0; i < chs.length; i += 3) {
    chunks.push(chs.slice(i, i + 3).reverse().join(''))
  }

  return chunks.reverse().join(delimiter)
}

const CartRow = ({ product, removeProduct }) => {
  return (
    <div className="row align-items-center justify-content-between mb-3 p-0">
      <div className="col-sm-7 d-flex align-items-center">
        <img
          src={product.display.image_urls[0].small}
          style={{ width: '100px' }}
        />
        <a href={product.display.url} className="ms-4 fw-medium">
          {product.display.name}
        </a>
      </div>
      <div className="col-sm-5 d-flex align-items-center justify-content-end fw-medium">
        {product.price_sale === null ? (
          <span className="me-4">
            {numberWithPrecision(product.price_normal)}
            &nbsp;
            {product.currency}
          </span>
        ) : (
          <>
            <del className="me-2">
              {numberWithPrecision(product.price_normal)}
            </del>
            <span className="me-2 text-danger">
              {numberWithPrecision(product.price_sale)}
            </span>
            <span className="me-4">{product.currency}</span>
          </>
        )}
        <button
          type="button"
          className="btn-close me-2"
          onClick={removeProduct}
        ></button>
      </div>
    </div>
  )
}

const CartSummary = ({ products, removeProduct }) => {
  const totalPrice = products
    .map(p => p.price_sale === null ? p.price_normal : p.price_sale)
    .reduce((a, p) => a + p, 0)

  return (
    <div>
      <div className="d-flex justify-content-between">
        <span className="h5">PRODUS</span>
        <span className="h5">SUBTOTAL</span>
      </div>

      <hr />

      {products.map(product => (
        <CartRow
          key={product.sku}
          product={product}
          removeProduct={() => removeProduct(product)}
        />
      ))}

      <hr />

      <div className="d-flex justify-content-between">
        <span className="h5">TOTAL</span>
        <span className="h5">
          {numberWithPrecision(totalPrice)}
          &nbsp;
          {products[0].currency}
        </span>
      </div>
    </div>
  )
}

const BillingDetailsForm = ({ method, url, token }) => {
  const validation = {
    'name': null,
    'phone': null,
    'email': null,
    'region': null,
    'message': null,
  }

  for (const key in validation) {
    const [validationClass, setValidationClass] = useState('')
    const [feedbackClass, setFeedbackClass] = useState('valid-feedback')
    const [feedbackMessage, setFeedbackMessage] = useState('')

    validation[key] = {
      validationClass, setValidationClass,
      feedbackClass, setFeedbackClass,
      feedbackMessage, setFeedbackMessage,
    }
  }

  const onSubmit = (event) => {
    event.preventDefault()
    const headers = { 'Accept': 'application/json' }
    const formData = new FormData(event.target)

    formData.set('authenticity_token', token)
    axios.request({ method, url, headers, data: formData })
      .then((response) => {
        const { redirect_url } = response.data
        window.location.replace(redirect_url)
      })
      .catch((error) => {
        const { status, data } = error.response

        if (status !== 422) {
          console.error(error)
          return
        }
        for (const key in validation) {
          const {
            setValidationClass,
            setFeedbackClass,
            setFeedbackMessage,
          } = validation[key]

          if (key in data) {
            setValidationClass('is-invalid')
            setFeedbackClass('invalid-feedback')
            setFeedbackMessage(data[key][0])
          } else {
            setValidationClass('is-valid')
          }
        }
      })
  }

  return (
    <div>
      <h3 className="my-5">Detalii de facturare</h3>

      <div className="row justify-content-center">
        <form onSubmit={onSubmit} className="col-11 col-md-10">
          <div className="row mb-3">
            <label className="col-sm-2 col-form-label">
              Nume
              <span className="text-danger">*</span>
            </label>
            <div className="col-sm-10">
              <input
                name="order[name]"
                type="text"
                className={`form-control ${validation.name.validationClass}`}
              />
              <div className={validation.name.feedbackClass}>
                {validation.name.feedbackMessage}
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-sm-2 col-form-label">
              Telefon
              <span className="text-danger">*</span>
            </label>
            <div className="col-sm-10">
              <input
                name="order[phone]"
                type="text"
                className={`form-control ${validation.phone.validationClass}`}
              />
              <div className={validation.phone.feedbackClass}>
                {validation.phone.feedbackMessage}
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-sm-2 col-form-label">
              Email
              <span className="text-danger">*</span>
            </label>
            <div className="col-sm-10">
              <input
                name="order[email]"
                type="email"
                className={`form-control ${validation.email.validationClass}`}
              />
              <div className={validation.email.feedbackClass}>
                {validation.email.feedbackMessage}
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-sm-2 col-form-label">
              Regiunea
            </label>
            <div className="col-sm-10">
              <input
                name="order[region]"
                type="text"
                className={`form-control ${validation.region.validationClass}`}
              />
              <div className={validation.region.feedbackClass}>
                {validation.region.feedbackMessage}
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <label className="col-sm-2 col-form-label">
              Mesaj
            </label>
            <div className="col-sm-10">
              <textarea
                name="order[message]"
                rows="6"
                className={`form-control ${validation.message.validationClass}`}
              ></textarea>
              <div className={validation.message.feedbackClass}>
                {validation.message.feedbackMessage}
              </div>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-sm-2"></div>
            <div className="col-sm-10">
              <button type="submit" className="btn btn-dark w-100">
                Confirmă comanda
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

const Cart = ({ method, url, token, products: _products }) => {
  const [products, setProducts] = useState(_products)

  const removeProduct = (product) => {
    (new CartCookie()).delete(product.sku)
    cartCountAdd(-1)
    setProducts(products.filter(p => p.sku !== product.sku))
  }

  return (
    <div className="row justify-content-center">
      <div className="col-12 col-lg-9 mb-5">
        <h3 className="mt-4 mb-5">Coșul de cumpărături</h3>

        {products.length === 0 ? (
          <p style={{ marginBottom: '300px' }}>
            Nu aveți nici un produs în coș
          </p>
        ) : (
          <>
            <CartSummary
              products={products}
              removeProduct={removeProduct}
            />
            <BillingDetailsForm
              method={method}
              url={url}
              token={token}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default Cart
