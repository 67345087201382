import React, { useState, useRef } from 'react'
import axios from 'axios'

const ContactUsForm = ({ method, url, token }) => {
  const toastRef = useRef(null)
  const [toastMessage, setToastMessage] = useState('')

  const validation = {
    'name': null,
    'phone': null,
    'email': null,
    'message': null,
  }

  for (const key in validation) {
    const [validationClass, setValidationClass] = useState('')
    const [feedbackClass, setFeedbackClass] = useState('valid-feedback')
    const [feedbackMessage, setFeedbackMessage] = useState('')

    validation[key] = {
      validationClass, setValidationClass,
      feedbackClass, setFeedbackClass,
      feedbackMessage, setFeedbackMessage,
    }
  }

  const showToast = () => {
    bootstrap.Toast.getOrCreateInstance(toastRef.current).show()
  }

  const onSubmit = (event) => {
    event.preventDefault()
    const form = event.target
    const headers = { 'Accept': 'application/json' }
    const formData = new FormData(form)

    formData.set('authenticity_token', token)
    axios.request({ method, url, headers, data: formData })
      .then((response) => {
        for (const key in validation) {
          const { setValidationClass } = validation[key]
          setValidationClass('is-valid')
        }
        setToastMessage('Mesajul a fost transmis')
        showToast()
        form.reset()
      })
      .catch((error) => {
        if (!error.response) {
          console.error(error)
          return
        }
        const { status, data } = error.response

        if (status !== 422) {
          console.error(error)
          return
        }
        for (const key in validation) {
          const {
            setValidationClass,
            setFeedbackClass,
            setFeedbackMessage,
          } = validation[key]

          if (key in data) {
            setValidationClass('is-invalid')
            setFeedbackClass('invalid-feedback')
            setFeedbackMessage(data[key][0])
          } else {
            setValidationClass('is-valid')
          }
        }
      })
  }

  return (
    <form onSubmit={onSubmit}>
      <div className="row">
        <div className="col-md-6 mb-3">
          <div>
            <label className="col-form-label">
              Nume
              <span className="text-danger">*</span>
            </label>
          </div>
          <div>
            <input
              name="contact_form[name]"
              type="text"
              className={`form-control ${validation.name.validationClass}`}
            />
            <div className={validation.name.feedbackClass}>
              {validation.name.feedbackMessage}
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-3">
          <div>
            <label className="col-form-label">
              Telefon
              <span className="text-danger">*</span>
            </label>
          </div>
          <div>
            <input
              name="contact_form[phone]"
              type="text"
              className={`form-control ${validation.phone.validationClass}`}
            />
            <div className={validation.phone.feedbackClass}>
              {validation.phone.feedbackMessage}
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-12">
          <div>
            <label className="col-form-label">
              Email
              <span className="text-danger">*</span>
            </label>
          </div>
          <div>
            <input
              name="contact_form[email]"
              type="email"
              className={`form-control ${validation.email.validationClass}`}
            />
            <div className={validation.email.feedbackClass}>
              {validation.email.feedbackMessage}
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-md-12">
          <div>
            <label className="col-form-label">
              Mesaj
              <span className="text-danger">*</span>
            </label>
          </div>
          <div>
            <textarea
              name="contact_form[message]"
              rows="6"
              className={`form-control ${validation.message.validationClass}`}
            ></textarea>
            <div className={validation.message.feedbackClass}>
              {validation.message.feedbackMessage}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-center">
          <button type="submit" className="btn btn-dark w-100">
            Trimite mesajul
          </button>
          <div
            className="toast-container position-fixed end-0 p-3"
            style={{ top: '50px' }}
          >
            <div
              ref={toastRef}
              className="toast align-items-center"
              role="alert"
              style={{
                width: '250px',
                color: '#0f5132',
                backgroundColor: '#d1e7dd',
              }}
            >
              <div className="d-flex">
                <div className="toast-body ms-auto">
                  {toastMessage}
                </div>
                <button
                  type="button"
                  className="btn-close me-2 m-auto"
                  data-bs-dismiss="toast"
                ></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default ContactUsForm
