import React, { useState, useRef } from 'react'

import CartCookie from './CartCookie'
import cartCountAdd from './cartCountAdd'

const AddToCart = ({ icon, sku, in_cart }) => {
  const [inCart, setInCart] = useState(in_cart)
  const [toastMessage, setToastMessage] = useState('')
  const [toastStyle, setToastStyle] = useState({})
  const toastRef = useRef(null)

  const showToast = () => {
    bootstrap.Toast.getOrCreateInstance(toastRef.current).show()
  }

  const onClick = () => {
    const cartCookie = new CartCookie()

    if (cartCookie.has(sku)) {
      cartCookie.delete(sku)
      cartCountAdd(-1)
      setInCart(false)
      setToastMessage('Eliminat din coș')
      setToastStyle({ color: '#842029', backgroundColor: '#f8d7da' })
    } else {
      cartCookie.add(sku)
      cartCountAdd(+1)
      setInCart(true)
      setToastMessage('Adăugat în coș')
      setToastStyle({ color: '#0f5132', backgroundColor: '#d1e7dd' })
    }
    showToast()
  }

  return (
    <>
      <button
        onClick={onClick}
        className="btn btn-dark"
        style={{ width: '250px' }}
      >
        <span dangerouslySetInnerHTML={{__html: icon}}></span>
        {inCart ? 'Elimină din coș' : 'Adaugă în coș'}
      </button>

      <div
        className="toast-container position-fixed end-0 p-3"
        style={{ top: '50px' }}
      >
        <div
          ref={toastRef}
          className="toast align-items-center"
          role="alert"
          style={{ width: '250px', ...toastStyle }}
        >
          <div className="d-flex">
            <div className="toast-body ms-auto">
              {toastMessage}
            </div>
            <button
              type="button"
              className="btn-close me-2 m-auto"
              data-bs-dismiss="toast"
            ></button>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddToCart
