import Cookies from 'js-cookie'

class CartCookie {
  constructor() {
    this.key = '_numismatica_md_cart'
    this.value = new Set(
      (Cookies.get(this.key) || '')
      .split(',')
      .filter(sku => sku.length !== 0)
    )
  }

  has(sku) {
    return this.value.has(sku)
  }

  add(sku) {
    this.value.add(sku)
    this.save()
  }

  delete(sku) {
    this.value.delete(sku)
    this.save()
  }

  save() {
    Cookies.set(
      this.key,
      [...this.value].join(','),
      { expires: 365, sameSite: 'strict' },
    )
  }
}

export default CartCookie
